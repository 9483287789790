import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import * as styles from '../styles/about.module.css'

export default function About() {
  return (
    <Layout>
      <Helmet>
        <meta charSet='urtf-8'/>
        <title>Venture Games - About Us</title>
      </Helmet>
      
      <PageTitle title="About Us" bgImage="/ShooterBackgroundImage2.jpg"/>
      <div className={styles.about}>
        <img src="/ShooterSpaceAge_Ship.png" alt=""/>
        <p>
          Venture Games is a small and independent game studio based in Hamilton, New Zealand. <br/>
          Our goal is to develop and produce entertaining and engaging games which are made to a high quality.
          Founded in 2021 by <Link to="http://venturegames.net/jamesjohnstone" style={{textDecoration: 'underline'}}>James Johnstone</Link>, we have gone on to release an endless space shooter game for both Android and iOS called <Link to="/games/shooter-space-age" style={{textDecoration: 'underline'}}>Shooter: Space Age!</Link> which is based on the old arcade space shooter games but with a modern spin. This went on to reach <i>35k+ downloads</i> and now we are working on our next title which is planning to be released middle to late 2022.
        </p>
      </div>
    </Layout>
  )
}